<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<el-form-item label="时间">
						<el-date-picker v-model.trim="time" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
					</el-form-item>
					<el-form-item label="类型">						
						<el-select v-model="filters.type" placeholder="请选择类型" clearable class="w120">
							<el-option v-for="item in typeList" :key="item.value" :label="item.txt" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态">						
						<el-select v-model="filters.status" placeholder="请选择状态" clearable class="w120">
							<el-option v-for="item in statusList" :key="item.value" :label="item.txt" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
					</el-form-item>
				</span>
				<el-form-item>
					<el-button type="primary" @click="handleAdd" icon="el-icon-plus">创建</el-button>
				</el-form-item>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;">
			<!-- <el-table-column type="selection">
			</el-table-column> -->
			<el-table-column prop="id" label="ID" width="50" align="center">
			</el-table-column>
			<el-table-column prop="createtime" label="创建时间" width="130">
			</el-table-column>
			<el-table-column label="图片" width="180" align="center">
				<template slot-scope="scope">
					<img :src="scope.row.url" width="120px">
				</template>
			</el-table-column>
			<el-table-column prop="title" label="banner内容" sortable>
			</el-table-column>
			<el-table-column prop="link_url" label="跳转地址" sortable>
			</el-table-column>
			<el-table-column label="类型" width="120" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.type == 1">banner</span>
					<span v-if="scope.row.type == 2">广告图片</span>
					<span v-if="scope.row.type == 3">通知图片</span>
					<span v-if="scope.row.type == 4">非营运时间图片</span>
					<span v-if="scope.row.type == 5">其他</span>
				</template>
			</el-table-column>
			<el-table-column label="状态" width="180" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status == 1">无效</span>
					<span v-else>有效</span>
				</template>
			</el-table-column>
			<el-table-column prop="remark" label="备注" sortable>
			</el-table-column>
			<el-table-column label="操作" width="200">
				<template slot-scope="scope">
					<el-button type="primary" plain size="small" @click="changeStatus(scope.row.id,scope.row.type,scope.row.status)">修改状态</el-button>
					<el-button type="danger" plain size="small" @click="handleState(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!-- <el-button type="danger" @click="handleState('', '', '批量删除')" :disabled="this.sels.length===0" icon="el-icon-delete">批量删除</el-button> -->
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>

	</section>
</template>

<script>
	import { bannerList, stateAdminRule, deleteImg,statusImg} from '../../api/api';
	export default {
		data() {
			return {
				time:[],
				filters: {
					start_time:'',
					end_time:'',
					type:'',
					status:''
				},
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				sels: [],
				typeList:[
					{
						value: '',
						txt: '全部'
					},
					{
						value: 1,
						txt: 'banner'
					},
					{
						value: 2,
						txt: '广告图片'
					},
					{
						value: 3,
						txt: '通知图片'
					},
					{
						value: 4,
						txt: '非营运时间图片'
					},
					{
						value: 5,
						txt: '其他'
					},
				],
				statusList:[
					{
						value: '',
						txt: '全部'
					},
					{
						value: 1,
						txt: '无效'
					},
					{
						value: 2,
						txt: '有效'
					},
				]
			}
		},
		watch:{
			'time': function(newValue,oldVale){
				if(!newValue){
					this.filters.start_time = ''
					this.filters.end_time = ''
				}else{
					this.filters.start_time = this.time[0]
					this.filters.end_time = this.time[1]
				}					
			},
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取列表
			getList(val) {
                if(val == '0') this.page = 1
				let para = {
					...this.filters,
					page: this.page,
					name: this.filters.keyword,
					pagesize: this.pagesize	//分页条数
				};
				this.listLoading = true;
				bannerList(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//显示编辑界面
			handleState: function (id) {
				let para = {
					id:id
				};
				deleteImg(para).then((res) => {
					if(res.code == 1){
						this.getList();
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			changeStatus: function(id,type,sta){
				var status;
				if(sta == 1) status = 2;
				else status = 1;
				let para = {
					id,
					status,
					type
				};
				statusImg(para).then((res) => {
					if(res.code == 1){
						this.getList();
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				})
			},
			//跳转新增界面
			handleAdd: function () {
				this.$router.push({ name: '添加banner'})
			},
			//勾选
			selsChange: function (sels) {
				this.sels = sels;
			},
		},
		mounted() {
			//获取列表
			this.getList();
		}
	}
</script>
